function initScrollTo() {
	$(document).ready(function () {
		var linkHash = window.location.hash.replace("#", "");
		if (linkHash != '') {
			$.scrollTo('#js-' + linkHash + '', 800);
		}

	})
	$(document).on('click', '.js-scroll-to', function () {
		var w = $(window).outerWidth();
		var scroll = $(this).attr('data-scroll');
		var speed = parseInt($(this).attr('data-speed'), 10);
		// var offset = -$('.g-header-area').outerHeight();
		if (isNaN(speed)) {
			speed = +900;
		}
		if(w<430){
			$.scrollTo('.js-' + scroll + '', speed, );
			return false;
		}
		$.scrollTo('#js-' + scroll + '', speed, );
		return false;
	})
}
function orderSubsrShape(){
	let tl1 = new TimelineMax({
		repeat: -1,
		repeatDelay: 0, 
	})
	.to('#Path_7721-subscr', 2.5, {
		bezier: {
			curviness: 0,
			values: [{
					x: '+=40',
					y: '-=30',
				},
				{
					x: '+=30',
					y: '-=40',
				},
				// {
				// 	x: '+=35',
				// 	y: '-=32',
				// },
				{
					x: '+=50',
					y: '-=50',
				},
				{
					x: '+=30',
					y: '-=85',
				},
			],
		},
		rotation: 150,
		scaleX: 0.9,
		scaleY: 0.9,
		transformOrigin: 'center center',
		ease: Power0.easeNone,
	})
	.to('#Path_7721-subscr', 0.4, {
		autoAlpha: 0,
	});
 let tl2 = new TimelineMax({
		repeat: -1,
		repeatDelay: 0,
		// yoyo: true
	})
	.to('#Path_7722-subscr', 3.6, {
		bezier: {
			curviness: 0,
			values: [{
					x: '-=10',
					y: '-=15',
				},
				{
					x: '-=14',
					y: '-=27',
				},
				{
					x: '-=25',
					y: '-=42',
				},
				{
					x: '-=14',
					y: '-=70',
				},
				{
					x: '-=31',
					y: '-=85',
				},
				// {
				// 	x: '-=21',
				// 	y: '-=255',
				// },
			],
		},
		rotation: 150,
		scaleX: 0.9,
		scaleY: 0.9,
		transformOrigin: 'center center',
		ease: Power0.easeNone,
	})
	.to('#Path_7722-subscr', 0.4, {
		autoAlpha: 0,
	});

  let tl3 = new TimelineMax({
		repeat: -1,
		repeatDelay: 0,
		// yoyo: true
	})
	.to('#Path_462-subscr, #Path_459-subscr', 3.6, {
		bezier: {
			curviness: 0,
			values: [{
					x: '+=10',
					y: '-=5',
				},
				{
					x: '+=14',
					y: '-=27',
				},
				{
					x: '+=20',
					y: '-=42',
				},
				{
					x: '+=10',
					y: '-=70',
				},
				{
					x: '+=15',
					y: '-=135',
				}, 
			],
		},
		rotation: 150,
		scaleX: 0.9,
		scaleY: 0.9,
		transformOrigin: 'center center',
		ease: Power0.easeNone,
	})
	.to('#Path_462-subscr, #Path_459-subscr', 0.4, {
		autoAlpha: 0,
	});
setTimeout(function () {
	 let tl4 = new TimelineMax({
		repeat: -1,
		repeatDelay: 0,
		// yoyo: true
	})
	.to('#Path_463-subscr, #Path_458-subscr', 3.6, {
		bezier: {
			curviness: 0,
			values: [{
					x: '+=10',
					y: '-=5',
				},
				{
					x: '+=14',
					y: '-=27',
				},
				{
					x: '+=20',
					y: '-=42',
				},
				{
					x: '+=25',
					y: '-=70',
				},
				{
					x: '+=0',
					y: '-=135',
				}, 
			],
		},
		rotation: 150,
		scaleX: 0.9,
		scaleY: 0.9,
		transformOrigin: 'center center',
		ease: Power0.easeNone,
	})
	.to('#Path_463-subscr, #Path_458-subscr', 0.4, {
		autoAlpha: 0,
	});
}, 300)
}
$(document).on('click', '.js-order-join', function (e) {
	e.preventDefault();
		// let tl1 = new TimelineMax();
		// let tl2 =  new TimelineMax();
		// let tl3 = new TimelineMax();
		// let tl4 = new TimelineMax();
	if ($(this).hasClass('is-active')) {
		$('.js-order-main').slideDown(500);
		$('.js-order-subs').slideUp(500);
		$('.subscr__img').removeClass('is-active');
		$('img', this).hide();
		$(this).removeClass('is-active'); 
	} else {
		$(this).addClass('is-active');
		$('.subscr__img').addClass('is-active')
		$('.js-order-main').slideUp(500);
		$('.js-order-subs').slideDown(500);
		$('img', this).show();
		orderSubsrShape()
	
	}
})


$(document).ready(function () {
	const controller = new ScrollMagic.Controller();
	// const controller = new ScrollMagic.Controller();
	var h = $(window).outerHeight();
	var w = $(window).outerWidth();
	let yBottleTop = '-21%';
	let yBottleBottom = '39%';
if(w<530){
	 yBottleTop = '-4%';
	  yBottleBottom = '69%';
}
	const tlHero = new TimelineMax()

		.to('.hero__title', 0.5, {
			y: 0,
			autoAlpha: 1,
			ease: Power0.easeNone,
		}, '-=0.1')
		.to('.hero__text', 0.5, {
			y: 0,
			autoAlpha: 1,
			ease: Power0.easeNone,
		}, '-=0.1')
		.to('.hero__btn', 0.5, {
			y: 0,
			autoAlpha: 1,
			ease: Power0.easeNone,
		}, '-=0.5')
		.to('.hero__btn-img', 0.5, {
			autoAlpha: 1,
			ease: Power0.easeNone,
		}, '-=0.1')
		.to('.hero .first', 0.9, {
			top: yBottleTop,
			ease: Power0.easeNone,
		}, '-=1.5')
		.to('.hero .sec', 0.9, {
			top: yBottleBottom,
			ease: Power0.easeNone,
		}, '-=1.6')
		.to('#S9_Front', 8.9, {
			rotation: 720,
			y: '-=' + h,
			repeat: -1,
			yoyo: true,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		}, '-=0.9')
		.to('#S9_Front-2', 8.9, {
			rotation: 720,
			y: '+=' + h * 0.8,
			repeat: -1,
			yoyo: true,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		}, '-=8.9')
		.to('#Path_26, #Path_6-2', 8.9, {
			rotation: -10,
			scaleX: 0.8,
			scaley: 0.8,
			y: '-=30',
			repeat: -1,
			yoyo: true,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		}, '-=8.9')
		.to('#Path_27, #Path_10-2', 8.9, {
			rotation: 0,
			scaleX: 1.4,
			scaley: 1.4,
			x: '-=70',
			repeat: -1,
			yoyo: true,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		}, '-=8.9') 
		.to('#Path_2-2, #Path_22', 8.9, {
			rotation:  0,
			scaleX: 1.2,
			scaley: 1.2,
			y: '-=70',
			repeat: -1,
			yoyo: true,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		}, '-=8.9')
		.to('#Path_20, #Path_29-2', 8.9, {
			rotation:  0,
			scaleX: 1.2,
			scaley: 1.2,
			x: '-=70',
			repeat: -1,
			yoyo: true,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		}, '-=8.9')
		.to('#Path_4-2, #Path_24', 8.9, { 
			x: '+=30',
			scaleX: 1.2,
			scaley: 1.2,
			repeat: -1,
			yoyo: true,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		}, '-=8.9');


	$('.js-selected').click(function () {
		$('.js-select-list').toggleClass('is-active');
		$('.js-selected').toggleClass('rotate');

	});

	$('.js-select-list li').click(function () {
		var opt = $(this).text();
		var price = $(this).attr('data-price');
		var qty = $(this).attr('data-qty');
		var sum = $(this).attr('data-sum');
		var save = $(this).attr('data-save');
		var total = $(this).attr('data-total');
		var link = $(this).attr('data-link');

		if ($(this).hasClass('js-show-info')) {
			$('.js-hide-row').css('display', 'flex');
			console.log(save);
		} else {
			$('.js-hide-row').hide();
		}


		$('.js-selected').text(opt);
		$('.js-price').text(price);
		$('.js-qty').text(qty);
		$('.js-summa').text(sum);
		$('.js-save').text(save);
		$('.js-total').text(total);
		$('.js-order-btn').attr('href', link);

		$('.js-select-list li').removeClass('is-active');
		$(this).addClass("is-active");
		$('.js-select-list').toggleClass('is-active');
		$('#js-selected').toggleClass('rotate');


	});


	/***************** accordion  *****************/
	$(".js-accordion-header").click(function () {
		var content = $(this).next("div");
		var header = $(this);
		$(".js-accordion-header").removeClass("rotate");
		$(this).toggleClass("rotate");
		if (content.is(":visible")) {
			content.slideUp();
			$(".js-accordion-header").removeClass("rotate");
		} else {
			$(".js-accordion-content").slideUp();
			content.slideToggle(function () {
				//				$('html, body').animate({scrollTop: $(header).offset().top - 180}, 600);
			});
		}
	});
	$(window).scroll(function () {
		const w = $(window).outerWidth();
		if (w < 500) {
			if ($(window).scrollTop() > $(window).outerHeight() / 2) {

				$('.header').css({
					'transform': 'translateY(-100%)',
					'position': 'fixed'
				})
				$('.header').addClass('is-fixed')

			} else {
				$('.header').attr('style', '')
				$('.header').removeClass('is-fixed')
			}
			if ($(window).scrollTop() > $(window).outerHeight()) {

				$('.header').addClass('is-shown')

			} else {
				$('.header').removeClass('is-shown')
			}
		}
	});

	const tl = new TimelineMax()
		.to('.order__img-lamp', 0.7, {
y: '8%',
		})
		.staggerTo('.order__img-lamp #linear-gradient stop', 0.4, {
			cycle: {
				// stopColor: ['#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50', '#fceb50'],
				stopColor: ['#ffff00', '#ffff00', '#ffff00', '#ffff00', '#ffff00', '#fceb50'],
			},
		}, '-=.9')
	new ScrollMagic.Scene({
			triggerElement: '.order-wrap',
			triggerHook: 0.3,
			duration: '80%',
		})
		.setTween(tl)
		.addTo(controller);

	const tlIngr5 = new TimelineMax({
			repeat: -1,
			repeatDelay: 0,
			// yoyo: true
		})
		.to('#Path_7721, #Path_7721-m', 2.5, {
			bezier: {
				curviness: 0,
				values: [{
						x: '-=2',
						y: '-=20',
					},
					{
						x: '+=3',
						y: '-=27',
					},
					{
						x: '+=5',
						y: '-=32',
					},
					{
						x: '+=10',
						y: '-=40',
					},
					{
						x: '-=0',
						y: '-=85',
					},
				],
			},
			rotation: 150,
			scaleX: 0.9,
			scaleY: 0.9,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		})
		.to('#Path_7721, #Path_7721-m', 0.4, {
			autoAlpha: 0,
		});
	const tl7 = new TimelineMax({
			repeat: -1,
			repeatDelay: 0,
			// yoyo: true
		})
		.to('#Path_7722', 3.6, {
			bezier: {
				curviness: 0,
				values: [{
						x: '+=0',
						y: '-=20',
					},
					{
						x: '-=3',
						y: '-=27',
					},
					{
						x: '-=5',
						y: '-=32',
					},
					{
						x: '-=10',
						y: '-=40',
					},
					{
						x: '-=1',
						y: '-=155',
					},
				],
			},
			rotation: 150,
			scaleX: 0.9,
			scaleY: 0.9,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		})
		.to('#Path_7722', 0.4, {
			autoAlpha: 0,
		});
	setTimeout(function () {

		const tlIngr6 = new TimelineMax({
				repeat: -1,
				repeatDelay: 0,
			})
			.to('#Path_463, #Path_458', 2.8, {
				bezier: {
					curviness: 0,
					values: [{
							x: '-=2',
							y: '-=20',
						},
						{
							x: '+=3',
							y: '-=27',
						},
						{
							x: '+=5',
							y: '-=32',
						},
						{
							x: '+=10',
							y: '-=40',
						},
						{
							x: '-=0',
							y: '-=85',
						},
					],
				},
				rotation: 150,
				scaleX: 0.9,
				scaleY: 0.9,
				transformOrigin: 'center center',
				ease: Power0.easeNone,
			})
			.to('#Path_463,  #Path_458', 0.4, {
				autoAlpha: 0,
			});
	}, 800)
	setTimeout(function () {
		const tl = new TimelineMax({
				repeat: -1,
				repeatDelay: 0,
				// yoyo: true
			})
			.to('#Path_462, #Path_459', 2.7, {
				bezier: {
					curviness: 0,
					values: [{
							x: '-=2',
							y: '-=20',
						},
						{
							x: '+=3',
							y: '-=27',
						},
						{
							x: '+=5',
							y: '-=32',
						},
						{
							x: '+=10',
							y: '-=40',
						},
						{
							x: '-=0',
							y: '-=70',
						},
					],
				},
				rotation: 150,
				scaleX: 0.9,
				scaleY: 0.9,
				transformOrigin: 'center center',
				ease: Power0.easeNone,
			})
			.to('#Path_462, #Path_459', 0.4, {
				autoAlpha: 0,
			});
	}, 300)
	const tl8 = new TimelineMax({
			repeat: -1,
			repeatDelay: 0,
			// yoyo: true
		})
		.to('#Path_7721-m', 2.5, {
			bezier: {
				curviness: 0,
				values: [{
						x: '-=2',
						y: '-=20',
					},
					{
						x: '+=3',
						y: '-=27',
					},
					{
						x: '+=5',
						y: '-=32',
					},
					{
						x: '+=10',
						y: '-=40',
					},
					{
						x: '-=0',
						y: '-=85',
					},
				],
			},
			rotation: 150,
			scaleX: 0.9,
			scaleY: 0.9,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		})
		.to('#Path_7721-m', 0.4, {
			autoAlpha: 0,
		});
	const tl6 = new TimelineMax({
			repeat: -1,
			repeatDelay: 0,
			// yoyo: true
		})
		.to('#Path_7722-m', 3.6, {
			bezier: {
				curviness: 0,
				values: [{
						x: '+=0',
						y: '-=20',
					},
					{
						x: '-=3',
						y: '-=27',
					},
					{
						x: '-=5',
						y: '-=32',
					},
					{
						x: '-=10',
						y: '-=40',
					},
					{
						x: '-=1',
						y: '-=155',
					},
				],
			},
			rotation: 150,
			scaleX: 0.9,
			scaleY: 0.9,
			transformOrigin: 'center center',
			ease: Power0.easeNone,
		})
		.to('#Path_7722-m', 0.4, {
			autoAlpha: 0,
		});
	setTimeout(function () {

		const tlIngr6 = new TimelineMax({
				repeat: -1,
				repeatDelay: 0,
			})
			.to('#Path_463-m, #Path_458-m', 2.8, {
				bezier: {
					curviness: 0,
					values: [{
							x: '-=8',
							y: '-=20',
						},
						{
							x: '+=8',
							y: '-=27',
						},
						{
							x: '+=10',
							y: '-=32',
						},
						{
							x: '+=15',
							y: '-=40',
						},
						{
							x: '-=5',
							y: '-=85',
						},
					],
				},
				rotation: 150,
				scaleX: 0.9,
				scaleY: 0.9,
				transformOrigin: 'center center',
				ease: Power0.easeNone,
			})
			.to('#Path_463-m,  #Path_458-m', 0.4, {
				autoAlpha: 0,
			});
	}, 800)
	setTimeout(function () {
		const tl = new TimelineMax({
				repeat: -1,
				repeatDelay: 0,
				// yoyo: true
			})
			.to('#Path_462-m, #Path_459-m', 2.7, {
				bezier: {
					curviness: 0,
					values: [{
							x: '-=2',
							y: '-=20',
						},
						{
							x: '+=3',
							y: '-=27',
						},
						{
							x: '+=5',
							y: '-=32',
						},
						{
							x: '+=10',
							y: '-=40',
						},
						{
							x: '-=0',
							y: '-=60',
						},
					],
				},
				rotation: 150,
				scaleX: 0.9,
				scaleY: 0.9,
				transformOrigin: 'center center',
				ease: Power0.easeNone,
			})
			.to('#Path_462-m, #Path_459-m', 0.4, {
				autoAlpha: 0,
			});
	}, 300)

 


	const animateShape = () => {

		const DOM = {};
		DOM.svg = document.querySelector('.hero__layer svg');
		DOM.shapeEl1 = DOM.svg.querySelector('#Path_26');
		DOM.shapeEl2 = DOM.svg.querySelector('#Path_6-2');

		DOM.shapeEl3 = DOM.svg.querySelector('#Path_10-2');
		DOM.shapeEl4 = DOM.svg.querySelector('#Path_27');

		DOM.shapeEl5 = DOM.svg.querySelector('#Path_2-2');
		DOM.shapeEl6 = DOM.svg.querySelector('#Path_22');

		DOM.shapeEl7 = DOM.svg.querySelector('#Path_20');
		DOM.shapeEl8 = DOM.svg.querySelector('#Path_29-2');

		DOM.shapeEl9 = DOM.svg.querySelector('#Path_24');
		DOM.shapeEl10 = DOM.svg.querySelector('#Path_4-2');
		const shapes = [{
				path: `m 884.085,504.391 c 8.095,-50.252 61.716,-78.881 87.179,-122.955 23.044,-39.884 20.07,-93.3 -7.26,-130.38 -27.33,-37.08 -77.47,-55.734 -122.388,-45.531 -44.918,10.203 -82.08,48.689 -90.709,93.936 -8.292,43.494 14.13488,112.2695 26.10788,154.8975 11.973,42.628 5.34467,76.4281 -18.45933,113.7601 -23.409,36.71 -76.71655,46.5604 -85.80055,92.0644 -8.362,41.887 33.852,93.75 65.783,114.484 19.90998,12.77823 42.76663,20.23207 66.382,21.648 -10.673,-40.28 -11.5,-83.046 1.141,-122.563 14.423,-45.093 47.852,-84.768 90.618,-103.989 -9.645,-21.149 -16.271,-42.544 -12.594,-65.372 z`,
				pathAlt: `m 884.085,504.391 c 8.095,-50.252 61.716,-41.51374 87.179,-85.58774 23.044,-39.884 17.57885,-98.2823 -9.75115,-135.3623 -27.33,-37.08 -74.97885,-88.11896 -119.89685,-77.91596 -44.918,10.203 -37.23929,57.40803 -45.86829,102.65503 -8.292,43.494 -65.58194,78.63897 -53.60894,121.26697 11.973,42.628 21.53715,112.54978 -2.26685,149.88178 -23.409,36.71 -58.03292,35.35022 -67.11692,80.85422 -8.362,41.887 22.64182,35.20796 54.57282,55.94196 19.90998,12.77823 53.97681,78.77411 77.59218,80.19004 -10.673,-40.28 -11.5,-83.046 1.141,-122.563 14.423,-45.093 47.852,-84.768 90.618,-103.989 -9.645,-21.149 -16.271,-42.544 -12.594,-65.372 z`,
				animation: {
					path: {
						duration: 3000,
						easing: 'easeOutElastic',
						elasticity: 100
					},
				}
			}, 

			{
				path: `m 886.27,108.282 c 12.787,50.88 44.99245,87.06598 90.81145,112.61998 60.20005,33.571 141.59655,50.46102 208.44355,33.65502 34.013,-8.552     67.5,   -25.949 86.471,  -55.446   26.321,-40.927 17.418, -95.171    -9.765, -136.612   H 883.083 c -1.59077,15.330513 -0.51248,30.820747 3.187,45.783 z`,
				pathAlt: `m 886.27,108.282 c 12.787,50.88 74.93811,-2.771 87.28843,107.33545 105.99927,1.86383 126.71257,53.57555 193.55957,36.76955 48.1051,-61.39728 84.1455,2.64364 103.1165,-26.85336 26.321,-40.927 19.1795,-121.59364 -8.0035,-163.03464 H 883.083 c -1.59077,15.330513 -0.51248,30.820747 3.187,45.783 z`,
				animation: {
					path: {
						duration: 6000,
						easing: 'easeOutElastic',
						elasticity: 100
					},
				}
			}, 

			{
				path: `m 1298.813,193.707 c -55.229,1.075 -110.657,32.513 -133.532,82.792 -19.872,43.678 -7.037,94 -6.196,141.982 0.841,47.982 -13.385,101.419 -50.185,132.206 -32.484,27.18 -79.062,28.865 -121.144,24.05 -42.082,-4.815 -84.818,-14.753 -126.265,-6.019 -9.85917,2.09792 -19.46432,5.24935 -28.65,9.4 13.4,29.388 32.634,58.3 38.25,89.935 8.392,47.284 -17.516,97.849 -59.437,121.279 -21.393,11.956 -46.126,16.861 -70.571,15.339 4.12334,15.51714 9.65289,30.62602 16.52,45.139 34.094,72.119 102.422,129.932 181.492,140.5 79.07,10.568 165.318,-32.61 194.905,-106.691 12.96,-32.446 15.018,-68.1 23.583,-101.967 8.565,-33.867 26.264,-68.619 58.2,-82.781 32.924,-14.6 71.161,-3.454 104.611,9.894 33.45,13.348 68.515,29.126 104.029,23.148 1.949,-0.328 3.873,-0.733 5.781,-1.188 V 243.368 c -36.85,-30.874 -83.52,-50.594 -131.391,-49.661 z`,
				pathAlt: `m 1342.8507,248.31379 c -55.229,1.075 -105.3724,11.37489 -128.2474,61.65389 -19.872,43.678 -105.6815,30.58566 -104.8405,78.56766 0.841,47.982 94.067,140.17221 57.267,170.95921 -32.484,27.18 -137.1918,20.05745 -179.2738,15.24245 -42.082,-4.815 -84.818,-14.753 -126.265,-6.019 -9.85917,2.09792 -19.46432,5.24935 -28.65,9.4 13.4,29.388 32.634,58.3 38.25,89.935 8.392,47.284 -17.516,97.849 -59.437,121.279 -21.393,11.956 -46.126,16.861 -70.571,15.339 4.12334,15.51714 9.65289,30.62602 16.52,45.139 34.094,72.119 102.422,129.932 181.492,140.5 79.07,10.568 100.1422,-14.99491 129.7292,-89.07591 97.5124,-30.68449 103.0934,-36.39283 111.6584,-70.25983 8.565,-33.867 12.1719,-61.57296 44.1079,-75.73496 32.924,-14.6 50.0229,-140.85173 83.4729,-127.50373 33.45,13.348 80.8456,110.15543 116.3596,104.17743 1.949,-0.328 3.873,-0.733 5.781,-1.188 V 243.368 c -36.85,-30.874 -39.4823,4.01279 -87.3533,4.94579 z`,
				animation: {
					path: {
						duration: 4000,
						easing: 'easeOutElastic',
						elasticity: 100
					},
				}
			}, 

			{
				path: `m 1154.588,597.307 c -42.739,-24.376 -98.3075,0.97451 -132.9575,31.09751 -33.85497,29.433 -58.9505,73.61749 -53.7245,118.17349 1.37392,11.44337 4.39588,22.62784 8.972,33.206 h 221.29 c 2.081,0 4.006,-0.008 5.9,0 9.7126,-16.64357 15.913,-35.10023 18.219,-54.232 v -28.38 c -4.871,-41.872 -30.987,-78.927 -67.699,-99.865 z`,
				pathAlt: `m 1154.588,597.307 c -42.739,-24.376 -98.3075,0.97451 -132.9575,31.09751 -15.298,52.5681 -47.58996,76.45763 -53.7245,118.17349 1.37392,11.44337 4.39588,22.62784 8.972,33.206 h 221.29 c 2.081,0 4.006,-0.008 5.9,0 9.7126,-16.64357 15.913,-35.10023 18.219,-54.232 v -28.38 c -40.1012,-38.34898 -30.987,-78.927 -67.699,-99.865 z`,
				animation: {
					path: {
						duration: 5000,
						easing: 'easeOutElastic',
						elasticity: 100
					},
				}
			}, 

			// {
			// 	path: `m 840.362,664.971 c -27.865,-16.312 -64.59751,-3.64947 -96.80351,-5.96847 -35.245,-2.536 -66.69949,-22.45553 -101.50149,-28.57653 -34.802,-6.121 -75.533,-1.3 -97.229,26.588 -18.555,23.852 -16.779,58.51 -1.889,85.156 h 323.422 c 9.976,-27.37 -0.43,-62.23 -25.999,-77.199 z`,
			// 	pathAlt: `M 840.362,664.971 C 812.497,648.659 795.14109,608.47625 743.55849,659.00253 674.84481,674.08162 676.859,636.547 642.057,630.426 c -34.802,-6.121 -75.533,-1.3 -97.229,26.588 -18.555,23.852 -16.779,58.51 -1.889,85.156 h 323.422 c 9.976,-27.37 -0.43,-62.23 -25.999,-77.199 z`,
			// 	animation: {
			// 		path: {
			// 			duration: 3000,
			// 			easing: 'easeOutElastic',
			// 			elasticity: 100
			// 		},
			// 	}
			// }, 
		];

		let step;

		const initShapeLoop = function (pos) {
			pos = pos || 0;
			// anime.remove(DOM.shapeEl1); 
			anime({
				targets: [DOM.shapeEl1, DOM.shapeEl2],
				easing: 'linear',
				d: [{
					value: shapes[0].pathAlt,
					duration: 3500
				}, {
					value: shapes[0].path,
					duration: 3500
				}],
				loop: true,
				direction: 'alternate'
			});
			anime({
				targets: [DOM.shapeEl3, DOM.shapeEl4],
				easing: 'linear',
				d: [{
					value: shapes[1].pathAlt,
					duration: 6000
				}, {
					value: shapes[1].path,
					duration: 6000
				}],
				loop: true,
				direction: 'alternate'
			});
			anime({
				targets: [DOM.shapeEl5, DOM.shapeEl6],
				easing: 'linear',
				d: [{
					value: shapes[2].pathAlt,
					duration: 4000
				}, {
					value: shapes[2].path,
					duration: 4000
				}],
				loop: true,
				direction: 'alternate'
			});
			anime({
				targets: [DOM.shapeEl7, DOM.shapeEl8],
				easing: 'linear',
				d: [{
					value: shapes[3].pathAlt,
					duration: 5000
				}, {
					value: shapes[3].path,
					duration: 5000
				}],
				loop: true,
				direction: 'alternate'
			});
			// anime({
			// 	targets: [DOM.shapeEl9, DOM.shapeEl10],
			// 	easing: 'linear',
			// 	d: [{
			// 		value: shapes[4].pathAlt,
			// 		duration: 3500
			// 	}, {
			// 		value: shapes[4].path,
			// 		duration: 3500
			// 	}],
			// 	loop: true,
			// 	direction: 'alternate'
			// });
		};


		initShapeLoop();
 

		// init();

	};
	animateShape();
});